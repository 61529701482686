@font-face {
  font-family: 'Druk Text';
  src: url('https://hotmic-public-content.s3.us-west-1.amazonaws.com/fonts/DrukText-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Druk Text Wide';
  src: url('https://hotmic-public-content.s3.us-west-1.amazonaws.com/fonts/DrukTextWide-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/Proxima-Nova.otf') format('truetype');
}

* {
  font-family: "Proxima Nova", sans-serif;
}

:where(.css-dev-only-do-not-override-3i5xx7).ant-typography:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
  font-family: "Proxima Nova";
}

p, span {
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

h1.ant-typography {
  font-family: "Druk Text";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
}

h2.ant-typography {
  font-family: "Druk Text";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
}

h3.ant-typography {
  font-family: "Druk Text Wide";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: uppercase;
  color: #FFFFFF;
}

h4.ant-typography {
  font-family: "Druk Text Wide";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  color: #FFFFFF;
}

h5.ant-typography {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
}

body {
  margin: 0;
  font-family: "Proxima Nova";
  height: 100vh;
  overflow: hidden;
}

.ant-pro-layout .ant-pro-layout-bg-list {
  background: transparent !important;
}
.ant-pro-layout .ant-pro-layout-content {
  padding-block: 0;
  padding-inline: 50px;
}

.ant-pro-layout .ant-pro-layout-content>div.ant-layout {
  padding-top: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #333;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #333;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccc;
}

.site-logo a {
  pointer-events: none;
}

.ant-pro-layout .ant-layout-header.ant-pro-layout-header {
  background: #000;
}

.ant-pro-top-nav-header-wide {
  max-width: 100% !important;
  padding-inline: 40px;
}

.gDgKOB {
  border-radius: 8px !important;
  background: #27262a !important;
  border: none !important;
  margin: 8px 0 !important;
}
.gDgKOB:hover {
  background: #2c2c2f !important;
}
.gDgKOB.selected {
  border: 1px solid #8752f6 !important;
  background: #541fc3 !important;
}

.ant-btn {
  box-shadow: none;
  border-radius: 12px;
}

.ant-btn.ant-btn-primary {
  border: none;
}

.ant-btn.ant-btn-link {
  border-color: transparent;
}

.borderless {
  border: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
}

.nobg {
  background: transparent !important;
}

span.gray {
  color: gray;
}